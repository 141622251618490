<template>
  <div class="wrap">
    <div class="scroll">
      <div class="top">
        <span style="color: #969696">可用蒸汽币:</span>
        <span style="font-weight: 400; margin-left: 10px">{{money.balance_money}}</span>
      </div>
      <div class="content">
      <List
  v-model="loading"
  :finished="finished"
  finished-text="没有更多了"
  @load="onLoad"
  :immediate-check="false"
>
  <div v-for="(item,index) in moneyList" :key="index">
          <div class="card">
            <div class="left">
            <div style="font-size:14p;width:250px;
            white-space:nowrap;overflow:hidden;
            text-overflow:ellipsis">{{item.record}}</div>
            <div style="font-size:12px;color:#969696;margin-top:5px">{{item.create_time}}</div>
          </div>
          <template>
            <div style="font-size:13px;color:#FF2222;font-weight:400" v-if="item.type == 0">+ 蒸汽币{{item.consume}}</div>
            <div style="font-size:13px;color:#5EA154;font-weight:400" v-else>- 蒸汽币{{item.consume}}</div>
          </template> 
          </div>
          <div style="height:1px;width:94%;margin:0 auto;background:#EEEEEE"></div>
        </div>
</List>
        
       
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { CellGroup, Cell, List } from "vant";
import BazzarApi from "@/api/bazaar.api"
import { momentFormat } from "@/utils/common.util"
@Component({
  components: {
    CellGroup,
    Cell,
    List
  },
})
export default class MoneyDetail extends Vue {
  moneyList = []
  money = []
  loading = false
  finished = false
  page = 1
  page_size = 10
  async created(){
    this.moneyList = await BazzarApi.getMoneyDetail()
    this.moneyList = this.moneyList.map(item => {
      let create_time = momentFormat(item.create_time)
      return {
        ...item,
        create_time
      }
    })
    this.money = await BazzarApi.getMoney()
    console.log(this.moneyList)
  }
  async onLoad() {
     
    this.page = this.page+1
     let res = await BazzarApi.getMoneyDetail(this.page,this.page_size)
     res = res.map(item => {
      let create_time = momentFormat(item.create_time)
      return {
        ...item,
        create_time
      }
    })
     res.forEach(item=>{
       this.moneyList.push(item)
     })
     this.loading = false
    if(res.length<10){
      this.finished = true
    }
     
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  background: white;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
}
.top {
  height: 40px;
  display: flex;
  border-top: 1px solid #f8f8f8;
  border-bottom: 1px solid #f8f8f8;
  align-items: center;
  font-size: 12px;
  padding: 0 20px;
  background: #f8f8f8;
}
.content {
    .card{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        .left{
            display: flex;
            flex-direction: column;
        }
    }
}
</style>